import React, { useState, useEffect, useMemo } from 'react'
import styles from '../CustomDomainModal.module.css'
import CTAButton from 'components/CTAButton/CTAButton'
import { ADD_DOMAIN } from '../constants/strings'
import { debounce } from 'lodash'
import CustomTooltip from "../../CustomTooltip";
import { Button } from 'react-bootstrap'

const AddDomainAddress = ({ formik, onNext, setActiveStep }) => {
  const { errors, values, handleChange } = formik
  const [proceed, setProceed] = useState(false)
  const [error, setError] = useState(false)
  const [showWarningBox, setShowWarningBox] = useState(false)
  const [domainValue, setDomainValue] = useState("")
  const isErrorDomain = !!errors.dnsAddress?.domain
  const isErrorSubdomain = !!errors.dnsAddress?.subdomain

  const handleValidate = () => {
    if (isErrorDomain || isErrorSubdomain) {
      setError(true)
    } else {
      setError(false)
      setProceed(true)
    }
  }

  const ProceedFromSubDomain = () => {
      if(!isErrorDomain  && !isErrorSubdomain){
        onNext()
      } else {
        setError(true)
      }
  }

  const debouncedUpdateDomain = useMemo(
    () =>
      debounce((value) => {
        setDomainValue(value)
      }, 1200),
    []
  )

  const onPurchaseDomain = () => {
    formik.setFieldValue(`dnsAddress.selected`, 'no')
    setActiveStep(2)
  }

  useEffect(() => {
    !isErrorDomain  && !isErrorSubdomain && setError(false)
  }, [isErrorSubdomain, isErrorDomain])

  useEffect(() => {
    if (values.dnsAddress.checked) {
      setProceed(false)
    }
    !values.dnsAddress.checked && formik.setFieldValue('dnsAddress.subdomain', '')
  }, [values.dnsAddress.checked])

  useEffect(() => {
    debouncedUpdateDomain(values.dnsAddress.domain)

    return () => {
      debouncedUpdateDomain.cancel(); // Cancel the debounced function
    };
  
  }, [values.dnsAddress.domain])


  useEffect(() => {
    if (values.dnsAddress.domain === domainValue) {
      const show = !values.dnsAddress.checked && domainValue && proceed
      setShowWarningBox(show)
    }
  }, [values.dnsAddress.checked, proceed, domainValue])

  return (
    <div className={styles.container}>
      <div className={styles.subHeading}>
        <div className={styles.title}>{ADD_DOMAIN.SUBHEADING}</div>
        <div className={styles.subTitle}>{ADD_DOMAIN.DESCRIPTION}</div>
      </div>
      <div className={styles.address}>
        <div className={styles.domainBox}>
          {values.dnsAddress.checked && (
            <>
              <input
                className={styles.addressInput}
                placeholder="subdomain"
                type="text"
                onChange={handleChange}
                name="dnsAddress.subdomain"
                value={values.dnsAddress.subdomain}
              />{' '}
              <span>.</span>
            </>
          )}
          <input
            className={styles.addressInput}
            placeholder="mywebsite.com"
            type="text"
            onChange={handleChange}
            name="dnsAddress.domain"
            value={values.dnsAddress?.domain}
          />
        </div>
        {(error || proceed) && errors?.dnsAddress?.domain && (
          <span className={styles['validation-error']}>
            {errors.dnsAddress.domain}
            <CustomTooltip label="Only letters, numbers & hyphens (-) are allowed. No spaces.">
              <span className="link ms-2">
                <u>Help</u>
              </span>
            </CustomTooltip>
          </span>
        )}
        {(error || proceed) && errors?.dnsAddress?.subdomain && (
          <span className={styles['validation-error']}>
            {errors.dnsAddress.subdomain}
            <CustomTooltip label="Only letters, numbers & hyphens (-) are allowed. No spaces.">
              <span className="link ms-2">
                <u>Help</u>
              </span>
            </CustomTooltip>
          </span>
        )}
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            checked={values.dnsAddress.checked}
            onChange={() => formik.setFieldValue(`dnsAddress.checked`, !values.dnsAddress.checked)}
          />
          <span className={styles.subTitle}>
            I want to use a subdomain{' '}
            <span className="font-small">
              (e.g. xyz.mydomain.com{' '}
              <a
                href="https://helpdesk.tiiny.host/en/article/connecting-your-custom-domain-using-a-subdomain-nnmug2/"
                target="_blank"
              >
                learn more
              </a>
              )
            </span>
          </span>
        </div>
      </div>
      {showWarningBox && (
        <div className={styles.warningBox}>
          <div className={styles.warningHeading}>
            <img src="/assets/icons/warning-yellow.svg" alt="warning" width="24" height="24" />
            <span>Warning</span>{' '}
          </div>
          <div className={styles.text}>
            <p>
              Any existing website hosted on <span>{domainValue}</span> will{' '}
              <u>
                <b>no longer be accessible</b>
              </u>{' '}
              if you continue.
            </p>
            <p>
              If you want to host your files on an address like <b>myfile.{domainValue}</b>, please select{' '}
              <b>Subdomain</b>.
            </p>
          </div>
        </div>
      )}
      <div className='mt-3'>
        <Button variant="outline-secondary" size="sm" onClick={onPurchaseDomain}>
          Or purchase a domain
        </Button>
      </div>
      <div className={styles.btnContainer} style={{ justifyContent: 'flex-end' }}>
        {values.dnsAddress.checked ? (
          <>
            <CTAButton
              label="Continue"
              onClick={ProceedFromSubDomain}
              size="small"
              className={styles.forwardButton}
              disabled={error}
            />
          </>
        ) : (
          <>
            {!error && proceed ? (
              <CTAButton
                label="Continue"
                onClick={onNext}
                size="small"
                className={styles.forwardButton}
                disabled={isErrorDomain || error}
              />
            ) : (
              <CTAButton label="Next" onClick={handleValidate} size="small" className={styles.forwardButton} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default AddDomainAddress
