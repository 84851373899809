import React, {useEffect, useState} from 'react';
import {Spinner} from "react-bootstrap";
import {connect} from "react-redux";
import Countdown from "react-countdown";

import BorderButton from "../BorderButton/index.b";
import {fetchUserData, showCustomDomainModal, showMessageModal} from "../../Manage/actions";
import {hasFeature, pluralize} from "../../utils/general";
import {autoConnectDomainAdd, autoConnectDomainFailed, deleteDomain,} from "../../services/custom-domain";
import {PRO_CUSTOM_DOMAIN_LIMIT, PLANS} from "../../constants/plans";
import ConfirmationModal from "../ConfirmationModal";
import LinkButton from "../LinkButton";
import CustomDomainModal from 'components/CustomDomainModal/CustomDomainModal'
import Details from "./components/Details";
import {ENTRI_APP_ID, SOURCE_LOGGED_IN } from "../../constants";
import {F_CUSTOM_DOMAIN} from "../../constants/plans/constants";
import { clickedCustomDomain } from 'utils/sendy';
import { setShowPaymentModal, showUpgradeCardModal } from 'actions'


import './CustomDomain.css'

export const STATUS_CONNECTED = 'CONNECTED';

const STATUS_INIT = 'INITIATED';
const STATUS_FAILED = 'FAILED';

export const getStatusLabel = (status = '') => {
  if (status.toUpperCase() === STATUS_CONNECTED) {
    return 'VALIDATED'
  } else {
    return status.toUpperCase();
  }
}

const confirmDeleteMessage = (domain) => {
  return `Are you sure you want to <b>${domain?.status === 'Connected' ? 'disable' : 'delete'}</b> this domain?${domain?.status === 'Connected' ? '<br /><b>All associated sites will also be deleted</b>.' : ''}`
}

const errorMessageDelete = {
  title: 'Delete failed',
  message: "Unable to delete domain, please contact support.",
  error: true
}

const inProgressMessage = {
  title: 'Domain update in progress',
  message: "Your domain is updating. This can take between 5-10 minutes. Please visit back later to permanently delete the domain.",
  error: true
}

export const domainErrorMessage = {
  show: true,
  error: true,
  title: 'Unable to set up domain',
  message: 'Please contact support.'
}

export const domainValidateErrorMessage = {
  show: true,
  error: true,
  title: 'Unable to set up domain',
  message: 'Sorry we\'ve been unable to validate your DNS settings. ' +
    'It may take a few hours to update. Please contact support if it\'s taking longer than 24 hours.'
}

const domainInitiatedMessage = {
  show: true,
  title: 'DNS Successfully updated',
  message: 'We need to validate your set-up. Please wait for at least 15 minutes to verify this.'
}

const getEntriConfig = (token, domain, certName, certValue, cfName, replaceWWW) => {
  const config = {
    applicationId: ENTRI_APP_ID,
    prefilledDomain: domain,
    token,
    dnsRecords: [
      {
        type: "CNAME",
        host: certName.split('.')[0],
        value: certValue,
        ttl: 300,
      },
      {
        type: "CNAME",
        host: "*",
        value: cfName,
        ttl: 300,
      },
    ],
  }

  if (replaceWWW) {
    config.dnsRecords.push({
        type: "CNAME",
        host: "www",
        value: cfName,
        ttl: 300,
      },
      {
        type: "A",
        host: "@",
        value: "18.168.91.127",
        ttl: 300,
      })
  }

  return config;
}

const pad = (num) => num.toString().length === 1 ? `0${num}` : num;
const renderer = (domain, onAction, onDelete) => ({minutes, seconds, completed}) => {
  if (completed) {
    return <div className='flex-records'>
      <LinkButton
        label="VALIDATE"
        onClick={() => onAction(domain, 'validate')}
      />
      <LinkButton
        label="DELETE"
        onClick={() => onDelete(domain)}
      />
    </div>;
  } else {
    return (
      <div className='flex-records'>
        <span>
          Please wait {pad(minutes)}:{pad(seconds)}
        </span>
        <LinkButton
          label="View Records"
          onClick={() => onAction(domain, 'viewrecords')}
        />
      </div>
    )
  }
};

const InitiatedView = ({domain, onAction, onDelete}) =>
  <span className="text-sm grey">
    <Countdown
      date={new Date(domain.created).getTime() + (900000)} // 15mins
      renderer={renderer(domain, onAction, onDelete)}
    />
  </span>;

const formatRecord = (CNAMEInfo, cloudfrontDomain, isRootDomain) => {
  let formattedRecords = []
  if (isRootDomain) {
    formattedRecords = [
      {
        type: 'A',
        host: '@',
        value: '18.168.91.127'
      },
      {
        type: 'CNAME',
        host: 'www',
        value: cloudfrontDomain
      },
      {
        type: 'CNAME',
        host: '*',
        value: cloudfrontDomain
      },
      {
        type: 'CNAME',
        host: CNAMEInfo.Name,
        value: CNAMEInfo.Value
      }
    ]

  } else {
    formattedRecords = [{
      type: 'CNAME',
      host: '*',
      value: cloudfrontDomain
    },
      {
        type: 'CNAME',
        host: CNAMEInfo.Name,
        value: CNAMEInfo.Value
      }]
  }

  return formattedRecords;
}

const getModalDataAsJSON = (record) => {
  const formattedRecord = formatRecord(record?.CNAMEInfo, record?.cloudfrontDomain, record?.isRootDomain)
  const data = JSON.stringify({
    ...record,
    domain: record?.domain,
    records: formattedRecord,
  })

  return data;
}

const CustomDomain = ({
  dispatch,
  className,
  customDomains = [],
  openModal,
  productId,
  customDomainsLimit,
  onSiteAction,
  userProfile,
}) => {
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState(customDomains);
  const [detailsModal, setDetailsModal] = useState({show: false, records: [], domain: ''});
  const [showModal, setShowModal] = useState(false);
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false);
  const [domainToDelete, setDomainToDelete] = useState();

  const domainLimit = customDomainsLimit || PRO_CUSTOM_DOMAIN_LIMIT[productId] || 5;
  const isTrial = !userProfile?.productId

  useEffect(() => {
    setShowModal(openModal)
  }, [openModal])

  useEffect(() => {
    setDomains(customDomains)
  }, [customDomains])

  const onEntriClose = (e) => {
    if (e.detail.success) {
      dispatch(showMessageModal(domainInitiatedMessage))
      dispatch(fetchUserData());
    } else {
      autoConnectDomainFailed({domain: e.detail.domain})
        .finally(() => {
          dispatch(showMessageModal(domainErrorMessage))
          dispatch(fetchUserData());
        })
    }
  }

  useEffect(() => {
    return () => {
      window.removeEventListener('onEntriClose', onEntriClose)
    }
  }, [])

  const setupWithEntri = (domain, token, cert, cfName, replaceWWW) => {
    setShowModal(false);
    window.addEventListener('onEntriClose', onEntriClose);
    window.entri.showEntri(getEntriConfig(token, domain, cert.name, cert.value, cfName, replaceWWW))
  }

  const handleCheckEntri = async (payload, formik) => {
    try {
      const {data} = await autoConnectDomainAdd(payload);
      if (data?.domain) {
        const {domain, token, cert, cfName} = data;
        formik.setFieldValue(`dnsRecords.data`, data)
        const domainCheck = await window.entri.checkDomain(domain, getEntriConfig(token, domain, cert.name, cert.value, cfName, payload.replaceWWW))
        const isManual = domainCheck?.setupType.toLowerCase() === 'manual' ? 'manual' : 'automatic'
        dispatch(fetchUserData(false));
        return isManual;
      }
    } catch (error) {
      dispatch(showMessageModal(domainErrorMessage))
    }
  }

  const onAddDomain = () => {
    if (!hasFeature(userProfile?.productId, F_CUSTOM_DOMAIN)) {
      dispatch(showUpgradeCardModal({
        title: 'Upgrade to connect your own website domain',
        desc: 'Use your own brand and upload content to your own website domain',
        cta: 'Connect domain',
        colourScheme: 'yellow',
        onClick: () => dispatch(setShowPaymentModal({showPaymentModal: true, upgradePlanId: PLANS.PRO.id}))
      }))
    } else if (customDomains.length >= domainLimit) {
      dispatch(showUpgradeCardModal({
        title: 'Upgrade to link more domains',
        desc: `Your current plan only allows ${domainLimit} custom ${pluralize('domain', 'domains', domainLimit)}`,
        cta: 'Add more domains',
        onClick: () => dispatch(setShowPaymentModal({showPaymentModal: true, upgradePlanId: PLANS.PRO_U.id}))
      }))   
    } else {
      setShowModal(true)
    }
    clickedCustomDomain()
  }

  const handleRetry = async (record) => {
    if(record?.isEnomDomain){
      setLoading(true)
      try {
        const { data } = await autoConnectDomainAdd({
          domain: record.domain,
          replaceWWW: true,
        });
        if (data?.domain) {
          setShowModal(true);
          localStorage.setItem('start_time', record.created);
          localStorage.setItem('view_records', JSON.stringify(data));
          dispatch(fetchUserData(false));  
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        dispatch(showMessageModal(domainErrorMessage))
      }
    } else {
      localStorage.setItem('retry', getModalDataAsJSON(record));
    }
  }

  const handleAction = async (record, action) => {
    
    switch (action) {
      case 'validate':
        setShowModal(true);
        localStorage.setItem('validate', getModalDataAsJSON(record));
        break;
      case 'retry':
        await handleRetry(record)
        break;
      case 'viewrecords':
        setShowModal(true);
        localStorage.setItem('start_time', record.created);
        localStorage.setItem('view_records', getModalDataAsJSON(record));
        break;
      default:
        break;
    }
  }
  
  const onUpload = (d) => {
    localStorage.setItem('active_custom_domain', `.${d.domain}`)
    localStorage.setItem('active_custom_subdomain', d?.subdomain ? d?.subdomain : 'www')
    onSiteAction()
  }

  const onDelete = (domain) => {
    setDomainToDelete(domain);
    setModalConfirmationShow(true);
  }

  const deleteCustomDomain = () => {
    setModalConfirmationShow(false);
    setLoading(true)

    deleteDomain({domain: domainToDelete.domain})
      .then(({data}) => {
        setLoading(false)
        if (data.success) {
          if (data.status === 'InProgress') {
            dispatch(showMessageModal(inProgressMessage));
            dispatch(fetchUserData());
          } else {
            dispatch(fetchUserData());
          }
        } else {
          dispatch(showMessageModal(errorMessageDelete));
        }
      })
      .catch(() => {
        setLoading(false)
        dispatch(showMessageModal(errorMessageDelete));
      })
  }

  const setShowDetailsModal = (domain) => {
    const formattedRecord = formatRecord(domain?.CNAMEInfo, domain?.cloudfrontDomain, domain?.isRootDomain)
    setDetailsModal({
      show: true,
      records: formattedRecord,
      domain: domain?.domain
    })
  }

  const onModalClose = () => {
    setShowModal(false)
    dispatch(showCustomDomainModal(false))
  }

  return (<div className={className}>
    <CustomDomainModal
      show={showModal}
      onClose={onModalClose}
      onSelectEntri={setupWithEntri}
      onFail={(msg) => dispatch(showMessageModal(msg))}
      setDomains={setDomains}
      refreshProfile={() => dispatch(fetchUserData(false))}
      onSiteAction={onSiteAction}
      handleCheckEntri={handleCheckEntri}
    />
    <ConfirmationModal
      title="Delete site"
      message={confirmDeleteMessage(domainToDelete)}
      show={modalConfirmationShow}
      handlePositive={deleteCustomDomain}
      handleNegative={() => setModalConfirmationShow(false)}
    />
    <Details
      show={detailsModal.show}
      onClose={() => setDetailsModal({show: false, records: [], domain: ''})}
      dnsData={detailsModal}
      domain={detailsModal?.domain}
    />
    <div className="flex align-items-baseline">
      <h6 className="bold me-3">Custom Domains</h6>
      <BorderButton
        className="tr-add-custom-domain"
        label="Add"
        onClick={onAddDomain}
      />
    </div>
    <div className="custom-domain-box overflow-auto p-3 mt-2">
      {loading ?
        <Spinner animation="border" variant="dark"/>
        :
        !!domains.length ?
          <table className="widget-table">
            <thead>
            <tr>
              <th style={{minWidth: '180px'}} className="table-header text-start">DOMAIN</th>
              <th className="table-header text-start status" style={{minWidth: 200}}>STATUS</th>
              <th className="table-header text-start">ACTIONS</th>
            </tr>
            </thead>
            <tbody>
            {domains.map((d, key) =>
              <tr key={key}>
                <td className="col-domain text-start pt-1 pb-1">
                  {d.domain}
                </td>
                <td className="text-start pt-1 pb-1 status">
                  <span className="grey font-small">{getStatusLabel(d.status)}</span>
                </td>
                <td className="text-start pt-1 pb-1">
                  {getStatusLabel(d.status) === STATUS_INIT &&
                  <InitiatedView
                    domain={d}
                    onDelete={onDelete}
                    onAction={handleAction}
                  />}
                  {getStatusLabel(d.status) === STATUS_FAILED &&
                  <div className='flex-records'>
                    <LinkButton
                      label="RETRY"
                      onClick={() => handleAction(d, 'retry')}
                    />
                    <LinkButton
                      label="DELETE"
                      onClick={() => onDelete(d)}
                    />
                  </div>}
                  {![STATUS_INIT, STATUS_FAILED].includes(getStatusLabel(d.status)) &&
                  <div className='flex-records'>
                    <LinkButton
                      label="UPLOAD"
                      onClick={() => onUpload(d)}
                    />
                    <LinkButton
                      label="DETAILS"
                      onClick={() => setShowDetailsModal(d)}
                    />
                    <LinkButton
                      label="DELETE"
                      onClick={() => onDelete(d)}
                    />
                  </div>}
                </td>
              </tr>)}
            </tbody>
          </table>
          :
          <div className="opacity-8">No custom domains</div>}
    </div>
    <div className="mt-2 flex align-items-center">
      <div className="light-grey text-sm ms-2">Issues?
        <span className="ms-1 link" onClick={() => window.$crisp.push(['do', 'chat:open'])}>
          <u>Contact us</u>
        </span>
      </div>
      <div className="live-site-limit text-sm light-grey float-right mt-2">
        {`${customDomains.length} / ${domainLimit} custom domain${domainLimit > 0 ? 's' : ''}`}
      </div>
    </div>
  </div>)
};

const mapStateToProps = ({manage}) => ({
  openModal: manage.customDomainModalVisible,
  userProfile: manage.userProfile
});

export default connect(mapStateToProps)(CustomDomain)
