import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPreviewData } from 'Manage/actions';
import styles from './PreviewModal.module.css';
import CTAButton from '../CTAButton/CTAButton';
import {onUpgradePlan, convertToBytes} from 'utils/general';
import CountdownTimer from 'components/CountDownTimer/CountDownTimer';
import {MAX_FREE_FILE_SIZE} from "../../constants";
import { uploadedFileTooBig } from 'utils/sendy';

const PreviewModal = ({ onHide, show, site }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    dispatch(setPreviewData({}));
    uploadedFileTooBig()
    onHide();
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const onPublish = (site) => {
    const fileSize = site?.size ? site?.size : convertToBytes(MAX_FREE_FILE_SIZE)
    onUpgradePlan({fileSize: fileSize}, dispatch)
    onClose();
  }

  const getDomain = (siteRecord) => {
    const subDomain = siteRecord?.subdomain || siteRecord?.link;
    const domain = siteRecord || '';
    return subDomain || domain;
  };

  return (
    <Modal size="xl" show={show} onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className={styles.body}>
        <iframe
          src={`https://${getDomain(site)}`}
          title="Link Preview"
          allowFullScreen
          className={styles.iframe}
        />
      </Modal.Body>
      <div className={`${styles.container} ${isOpen ? styles['slide-top'] : styles['slide-bottom']}`}>
        {isOpen ? (
          <div className={styles.open}>
            <div className={styles.dropdown} onClick={toggleAccordion}>
              <img src="/assets/icons/down-arrow.svg" alt="down-arrow" />
            </div>
            <div className={`${styles.content}`}>
              <div className={styles.timer}>
                <img src="/assets/icons/timer.svg" alt="timer" width="15" height="15" />
                {site?.created && <CountdownTimer creationTime={site.created} />}
              </div>
              <div className={styles.heading}>Link Preview</div>
              <div className={styles.subHeading}>
                <span className={styles.subTitle}>Your content is larger than your plan's limit</span>
              </div>
              <div className={styles.btnCon}>
                <CTAButton
                  className={styles.button2}
                  onClick={() => onPublish(site)}
                  label="Upgrade to Publish as a link"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={`${styles.closed}`}>
            <div className={styles.dropdown} onClick={toggleAccordion}>
              <img src="/assets/icons/up-arrow.svg" alt="down-arrow" />
            </div>
            <div className={`${styles.contentCollapsed}`}>
              <div className={styles.heading2}>Link Preview</div>
              <div className={styles.timer}>
                <img src="/assets/icons/timer.svg" alt="timer" width="20" height="20" />
                {site?.created && <CountdownTimer creationTime={site.created} />}
              </div>
              <div className={`${styles.btnCon} justify-content-end`}>
                <CTAButton
                  className={`${styles.button2} ${styles.small}`}
                  onClick={() => onPublish(site)}
                  label="Upgrade to publish as a link"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PreviewModal;
