import api from "../utils/api";
import {API_ROOT} from "../constants";

export const MAIL_LIST_CHECKOUT_DROP = 'Bpb48SmIysbdl4jTlsVlcQ'

export async function enrollInEmailList (email, list) {
  await api.post(`${API_ROOT}/email-list/subscribe`, { email, list });
}

export const downgradeSubscription = (payload) =>
  api.post(`${API_ROOT}/subscription/downgrade`, payload)

export const addSegment = (payload) =>
  api.post(`${API_ROOT}/add-segment`, payload)