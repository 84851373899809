import React, {useState} from 'react'
import {Button, Dropdown, ButtonGroup} from 'react-bootstrap'
import {copyToClipboard, getSubdomainAndTLD} from 'utils/general'

const Share = ({url, directFileUrl, onQRClick, type, trial, passwordProtected, onEmbed, onUpgrade}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const urlParams = getSubdomainAndTLD({site: {subdomain: url}})
  const title = "Check out this link!"
  const redirect_url = `${urlParams.subdomain}.tiiny.xyz/?d=${urlParams.tld.replace(/^\./, '')}`

  const handleSocialShare = (network) => {
    switch (network) {
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${redirect_url}`, '_blank');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${redirect_url}&quote=${encodeURIComponent(title)}`, '_blank');
        break;
      default:
        console.error('Unsupported network');
    }
  }

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div
      className="d-flex flex-column share-background w-100 align-items-center justify-items-center pb-4 pt-3 mt-4 px-3 round-8">
      <p className="font-16 share-text">Share via</p>
      <div className="d-flex align-items-center gap-2 flex-wrap justify-content-center">
        <Dropdown as={ButtonGroup} size="sm">
          <Button className="custom-button" variant="light" onClick={() => copyToClipboard(url)}>
            <img height="16" width="16px" src="/assets/icons/link.svg" className="me-1" alt="Copy link"/>
            Copy link
          </Button>
          {!passwordProtected && directFileUrl && type === 'PDF' && (
            <>
              <Dropdown.Toggle split variant="outline-secondary"/>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => copyToClipboard(directFileUrl)} disabled={trial}>
                    <span className="font-14">Copy direct file link <UpgradeBadge show={trial}
                                                                                  onUpgrade={onUpgrade}/></span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </>
          )}
        </Dropdown>
        <Button
          className="custom-button d-flex gap-1 align-items-center"
          variant="light"
          size="sm"
          onClick={onQRClick}
        >
          <img height="16" width="16px" src="/assets/icons/qr-code.svg" alt="QR Code"/>
          QR code
        </Button>

        <Button
          className="custom-button d-flex gap-1 align-items-center"
          variant="light"
          size="sm"
          onClick={onEmbed}
        >
          <img height="16" width="16px" src="/assets/icons/embed.svg" alt="Embed"/>
          Embed
        </Button>

        {!trial && <Dropdown as={ButtonGroup} show={showDropdown} onToggle={handleToggleDropdown}>
          <Button className="custom-button d-flex gap-1 align-items-center" variant="light" size="sm"
                  onClick={handleToggleDropdown}>
            <img src="/assets/icons/share.svg" height={16} width={16}/>
            Share
          </Button>
          <Dropdown.Toggle split variant="secondary" id="dropdown-custom-2" className="py-0"/>
          <Dropdown.Menu className="super-colors">
            <Dropdown.Item
              eventKey="1"
              className=" d-flex gap-2 align-items-baseline"
              onClick={() => handleSocialShare('facebook')}
            >
              <img alt="Facebook" src="/assets/icons/facebook.svg" height={16} width={16}/> Facebook
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              className=" d-flex gap-2 align-items-baseline"
              onClick={() => handleSocialShare('linkedin')}
            >
              <img alt="LinkedIn" src="/assets/icons/linkedin.svg" height={16} width={16}/> Linkedin
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              className=" d-flex gap-2 align-items-baseline"
              onClick={() => copyToClipboard(redirect_url)}
            >
              <img alt="copy" src="/assets/icons/copy.png" height={16} width={16}/> Copy social link
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>}
      </div>
    </div>
  )
}

export default Share
