import { useState, useEffect } from 'react'
import styles from '../CustomDomainModal.module.css'
import CTAButton from 'components/CTAButton/CTAButton'
import { DNS_RECORDS, VALIDATE_RECORDS } from '../constants/strings'
import DNSTable from './DNSTable'
import { Spinner } from 'react-bootstrap'
import { autoConnectDomainFinish, autoConnectDomainRetry } from '../../../services/custom-domain'
import { domainErrorMessage } from '../../CustomDomain/index'

const DNSRecords = ({ formik, onNext, updateDomains, refreshProfile, onFail }) => {
  const [loading, setLoading] = useState(false)
  const { values } = formik
  const dnsData = values.dnsRecords.data
  const validate = localStorage.getItem('validate')
  const retry = localStorage.getItem('retry')
  const domain = values.dnsAddress.domain
  const subdomain = values.dnsAddress.subdomain
  const fulldomainAddress = !!subdomain ? `${subdomain}.${domain}` : domain

  const handleNext = () => {
    const createdTime = localStorage.getItem('start_time')
    if (!createdTime) localStorage.setItem('start_time', new Date())
    localStorage.setItem('form_values', JSON.stringify(values))
    onNext()
  }

  const handleRetry = async() => {
    setLoading(true)
    try {
      if (!!fulldomainAddress) {
        const { data: {success}} = await autoConnectDomainRetry({ fulldomainAddress })
        if (success){
          refreshProfile()
          localStorage.setItem('start_time', dnsData?.created)
          onNext()
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      onFail(domainErrorMessage)
    }
  }

  const onValidate = async () => {
    setLoading(true)
    try {
      if (!!fulldomainAddress) {
        const {
          data: { domains, success }
        } = await autoConnectDomainFinish({ domain: fulldomainAddress })
        if (success) {
          updateDomains(domains)
          formik.setFieldValue(`isSetUpComplete`, true)
          localStorage.setItem('active_custom_domain', `.${domain}`)
          localStorage.setItem('active_custom_subdomain', subdomain ? subdomain : 'www')
          refreshProfile()
        } else {
          formik.setFieldValue(`validationFailed`, true)
        }
      }
      setLoading(false)
    } catch (error) {
        formik.setFieldValue(`validationFailed`, true)
        setLoading(false) 
    }
    onNext()
  }

  useEffect(() => {
    if (!!validate) {
      const data = JSON.parse(validate)
      formik.setFieldValue(`dnsAddress.domain`, data?.domain)
      formik.setFieldValue(`dnsRecords.data`, data)
      formik.setFieldValue(`dnsAddress.subdomain`, data?.subdomain)
    }
  }, [validate])

  return (
    <div className={styles.container}>
      {!!validate ? (
        <>
          <div className={styles.subHeading}>
            <div className={styles.title}>{loading ? VALIDATE_RECORDS.UPLOADING : VALIDATE_RECORDS.SUBHEADING}</div>
            <div className={styles.subTitle}>
              {loading ? VALIDATE_RECORDS.UPLOADING_DESC : VALIDATE_RECORDS.DESCRIPTION}
              {!loading && (
                <div className={styles.recordsCon}>
                  <DNSTable data={dnsData} domain={domain} />
                </div>
              )}
            </div>
          </div>

          <div className={`${styles.btnContainer} ${!!validate && !loading ? styles.right : styles.center}`}>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <CTAButton label="Validate" onClick={onValidate} size="small" className={styles.forwardButton} />
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.subHeading}>
            <div className={styles.title}>{DNS_RECORDS.SUBHEADING}</div>
            <div className={styles.subTitle}>{DNS_RECORDS.DESCRIPTION}</div>
          </div>
          <div className={styles.recordsCon}>
            <DNSTable data={dnsData} domain={domain}/>
          </div>
          <div className={styles.checkbox}>
            <input
              type="checkbox"
              checked={values.dnsRecords.checked}
              onChange={() => formik.setFieldValue(`dnsRecords.checked`, !values.dnsRecords.checked)}
            />
            <span className={styles.subTitle}>
              I have added the above DNS records
            </span>
          </div>
          <div className={`${styles.btnContainer} ${styles.right}`}>
            <CTAButton
              label="Next"
              onClick={!!retry ? handleRetry : handleNext}
              size="small"
              disabled={!values.dnsRecords.checked}
              className={styles.forwardButton}
              loading={loading}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default DNSRecords
