import React, { useRef, useState, useEffect } from 'react'
import MoreOptions from '../MoreOptions/MoreOptions'
import styles from './OverflowMenu.module.css'

const OverflowMenu = ({
  onEditHTML,
  onEditPDF,
  onArchive,
  onDownload,
  onAnalytics,
  onQRCode,
  onDelete,
  onCopy,
  onReplaceFile,
  onAddCustomDomain,
  type,
  onEmbed,
  passwordProtected,
  enableEmailGate,
  link,
  linkPreview,
  onShare,
  trial,
  disabled,
  onUpgrade
}) => {
  const [show, setShow] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const ref = useRef(null)

  const handleClick = () => {
    setShow(!show)
  }

  const handleBlur = () => {
    setShow(false)
  }

  const handleHover = () => {
    setShow(true)
  }

  const onCustomisePasswordPage = () => {
    window.open(`https://tiiny.host/editor/?domain=${link}&file=/_auth/index.html&customize-auth=true`, '_blank')
  }

  useEffect(() => {
    const checkIsDesktop = () => {
      setIsDesktop(window.innerWidth > 1200)
    }
    checkIsDesktop()
    window.addEventListener('resize', checkIsDesktop)
    return () => {
      window.removeEventListener('resize', checkIsDesktop)
    }
  }, [])

  return (
    <div
      className={styles.overflowMenu}
      ref={ref}
      onClick={handleClick}
      onMouseLeave={handleBlur}
      onMouseOver={isDesktop ? handleHover : null}
      tabIndex={0}
    >
      <img className={styles.overflowIcon} src="/assets/icons/menu.svg" height={12} />
      {show && !disabled && (
        <div className={styles.optionCon}>
          <MoreOptions
            onEditHTML={onEditHTML}
            onEditPDF={onEditPDF}
            onEmbed={onEmbed}
            onArchive={onArchive}
            onDownload={onDownload}
            onAnalytics={onAnalytics}
            onQRCode={onQRCode}
            onDelete={onDelete}
            onCopy={onCopy}
            onReplaceFile={onReplaceFile}
            onCustomisePasswordPage={onCustomisePasswordPage}
            onAddCustomDomain={onAddCustomDomain}
            type={type}
            passwordProtected={passwordProtected}
            enableEmailGate={enableEmailGate}
            linkPreview={linkPreview}
            onShare={onShare}
            trial={trial}
            link={link}
            onUpgrade={onUpgrade}
          />
        </div>
      )}
    </div>
  )
}

export default OverflowMenu
