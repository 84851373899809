import React, { useState, useEffect, useCallback } from 'react'
import styles from '../CustomDomainModal.module.css'
import CTAButton from 'components/CTAButton/CTAButton'
import { CONN_PATH } from '../constants/strings'
import { getAvailableDomains, purchaseDomain, autoConnectDomainAdd, checkDomain, makeEnomStripePayment } from 'services/custom-domain'
import { Button, Spinner } from 'react-bootstrap'
import {fetchUserData} from "../../../Manage/actions";
import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'

const initialValues = {
  name: '',
  price: '',
  status: '',
  available: false,
  payment_success: false,
  isPremium: false
}

const GetDomain = ({ formik, onPrevious, setActiveStep, onMessage, userProfile, showMessageModal }) => {
  const { values } = formik
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [searchTerm, setSearchTerm] = useState({value: '', exists: false})
  const dispatch = useDispatch()
  const domainSuggestions = values.path.domain_search
  const selectedDomain = values.path.selected_domain
  const isStripeCustomer = `${userProfile?.customerId}`?.startsWith('cus_');

  useEffect(() => {
    if (userProfile?.payments?.length ) {
      const paymentFound = userProfile.payments.some(payment => payment.domain.toLowerCase() === selectedDomain.name.toLowerCase())
      if (paymentFound) {
        formik.setFieldValue('path.selected_domain.payment_success', true)
      }
    }
  }, [userProfile?.payments, selectedDomain.payment_success, showMessageModal])

  const getDomains = async () => {
    try {
      setLoading(true)
      formik.setFieldValue('path.selected_domain', initialValues)
      const { data: {domains, searchedDomainAvailable} } = await getAvailableDomains({ domain: values.dnsAddress.domain })
      const domain_search_result = domains?.length > 0 ? domains : []
      setSearchTerm({
        value: values.dnsAddress.domain,
        exists: searchedDomainAvailable
      })
      formik.setFieldValue('path.domain_search', domain_search_result)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const handleSelectDomain = useCallback(async (name) => {
    if (selectedDomain.name.toLowerCase() === name.toLowerCase()) {
      formik.setFieldValue('path.selected_domain.name', '');
    } else {
      formik.setFieldValue('path.selected_domain.name', name.toLowerCase());
      await checkDomainValidity(name);
    }
  }, [selectedDomain.name]);

  const handleNext = () => {
    const createdTime = localStorage.getItem('start_time')
    if (!createdTime) localStorage.setItem('start_time', new Date())
    formik.setFieldValue(`dnsRecords.checked`, true)
    setActiveStep(4)
  }

  const autoAddDomain = async () => {
    try {
      const payload = {
        domain: selectedDomain.name.toLowerCase(),
        replaceWWW: true,
      };
      const { data } = await autoConnectDomainAdd(payload);
      if (data?.domain) {
        formik.setFieldValue('dnsRecords.data', data);
        handleNext();
      }
      dispatch(fetchUserData(false));
    } catch (error) {
      console.error(error);
      onMessage({
        show: true,
        title: 'Ooops',
        message: `Domain purchase successful, but we encountered an error setting up your domain. Retry from your dashboard`
      });
    }
  }

  const onBuyDomain = async () => {
    const premiumPrice = selectedDomain?.isPremium ? selectedDomain.price : null
    const { data: { success, message }} = await purchaseDomain({ domain: selectedDomain.name, price: premiumPrice, email: userProfile.email })
    if (success) {
      onMessage({
        show: true,
        title: 'Domain purchase successful',
        message: `Congratulations, you now own ${selectedDomain.name}`
      })
      await autoAddDomain()
      formik.setFieldValue('path.selected_domain', initialValues)
    } else {
      onMessage({
        show: true,
        title: 'Domain purchase failed, please retry',
        message: message
      })
    }
  };

  const checkDomainValidity = async (domain) => {
    try {
      setIsVerifying(true)
      const { data } = await checkDomain({ domain: domain.toLowerCase() })
      const Code = data?.RRPCode
      const Price = data?.Price
      const isPremium = data?.isPremium
      if (Code === '210' && Price > 0) { 
        const form_data = {
          name: data.Name,
          price: Price,
          available: true,
          status: data?.RRPText,
          payment_success: false,
          isPremium: isPremium
        }
        formik.setFieldValue('path.selected_domain', form_data)
        setIsVerifying(false)
      } else {
        formik.setFieldValue('path.selected_domain', {
          name: domain.toLowerCase(),
          price: '',
          status: data?.RRPText,
          available: false,
          payment_success: false,
          isPremium: false
        })
        setIsVerifying(false)
      }
    } catch (error) {
      setIsVerifying(false)
    }
  }

  const onPayWithStripe = async () => {
    if(isStripeCustomer){
      try {
        setIsLoading(true)
        let paymentSuccessful = selectedDomain.payment_success;
        if(!paymentSuccessful){
          const payload = {
            customerId: userProfile.customerId,
            amount:  selectedDomain.price,
            domain: selectedDomain.name,
            email: userProfile.email
          }
          const { data: { success }} = await makeEnomStripePayment(payload)
          if (success) {
            paymentSuccessful = true;
            formik.setFieldValue('path.selected_domain.payment_success', true);
          }
        }
       // Proceed to buy the domain on enom only if stripe payment was successful
      if (paymentSuccessful) {
        await onBuyDomain();
      }
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        onMessage({
          show: true,
          title: 'Payment Error',
          message: 'Sorry, an error occurred whilst making your payment'
        })
      }
    } else {
      onMessage({
        show: true,
        title: 'Feature Not Available',
        message: 'Sorry this feature is not currently available for your account. We recommend Godaddy.com as an alternative'
      })
    }
  };

  return (
    <>
      <div className={styles.subHeading}>
        <div className={styles.title}>{CONN_PATH.PURCHASE_TITLE}</div>
        <div className={styles.subTitle}>{CONN_PATH.PURCHASE_DESC}</div>
        <div className={styles.goDaddyInput}>
          <input className={styles.input} placeholder="e.g. mywebsite.com" type="text" {...formik.getFieldProps(`dnsAddress.domain`)} />
          <CTAButton label="Search for domain" onClick={getDomains} size="small" disabled={!formik.values.dnsAddress.domain} className={styles.domainSearch} loading={loading} />
        </div>

        {domainSuggestions.length > 0 && (
          <>
            <div className={styles.subTitle}>{`Suggested Domains (${searchTerm.value} ${searchTerm.exists ? 'is available' : 'is not available'})`}</div>
            <div className={styles.domainSearchCon}>
              {domainSuggestions.map((domain) => {
                return (
                  <div className={styles.domainSearch} key={domain._}>
                    <span className={styles.subTitle}>{domain._.toLowerCase()}</span>
                    <div className={styles.btnCon} onClick={() => handleSelectDomain(domain._)}>
                    {selectedDomain.name.toLowerCase() === domain._.toLowerCase() ? (
                      isVerifying ? (
                        <Spinner animation="border" size="sm" />
                      ) : selectedDomain.available && selectedDomain.price ? (
                        <Button variant="primary" size="sm" className={`${styles.btn} ${styles.activeOption}`}>
                          {`$ ${selectedDomain.price}`}
                        </Button>
                      ) : (
                        <span className={styles.unavailable}>Unavailable</span>
                      )
                    ) : (
                      <Button variant="outline-primary" size="sm" className={styles.btn}>
                        View price
                      </Button>
                    )}
                  </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
        {selectedDomain.status && selectedDomain.price && !isVerifying && <span className={styles.title}>{selectedDomain.status.substring(0, 53)}</span>}
        {selectedDomain.available && !isVerifying && (
          <div className={styles.selectedDomain}>
            <span>
              Continue to buy <u> {`${selectedDomain.name}`}</u> for ${`${selectedDomain.price}`} for 1 year.
            </span>
            <span className={styles.subTitle}>We will automatically charge the payment method on your account.</span>
          </div>
        )}
      </div>
      <div className={styles.btnContainer}>
        <CTAButton
          label="Back"
          onClick={onPrevious}
          size="small"
          className={styles.backButton}
          showLeftArrow={true}
          showArrow={false}
          leftArrowClass={styles.backArrow}
        />
        <CTAButton
          label={'Buy domain'}
          onClick={onPayWithStripe}
          size="small"
          className={styles.forwardButton}
          disabled={!selectedDomain.available || isLoading}
          loading={isLoading}
        />
      </div>
    </>
  )
}
const mapStateToProps = ({ manage }) => ({
  userProfile: manage.userProfile,
  showMessageModal: manage.showMessageModal
})
export default connect(mapStateToProps)(GetDomain)
