import React from 'react'
import styles from './CategoryTab.module.css'

const CategoryTab = ({text, icon, activeIndex, index, onClick, href}) => {
  return (
    <a className={`${styles.container} ${styles.transition} ${activeIndex === index ? styles.activeContainer : ''}`} onClick={onClick} href={href}>
        <div className={styles.category}>
            <div className={`${styles.imgCon}`}>
              <img src={icon} className={`${index === activeIndex ? styles.activeIcon : styles.img}`}/>
            </div>
            <div className={`${index === activeIndex ? styles.active : styles.text}`}>{text}</div>
        </div>
        <span className={`${index === activeIndex ? styles.arrowActive : styles.arrow}`}>&rarr;</span>
    </a>
  )
}

export default CategoryTab